export function getTextsPL(data) {

  return {
    "continueLater": {
      // 0
      "floatingButtonTooltip": "Zapisz na póżniej",
      // ContinueLater texts when closeTabWarning prop is not set.
      "save": {
        // 1
        "title": "Chcesz kontynuować zakup później?",
        // 1a
        "validityText": "Pamiętaj, że link wygaśnie {{data.linkValidityText}}!",
        "textLine1": "Klikając „Wyślij”, otrzymasz e-mail z linkiem, za pomocą którego możesz kontynuować zakup pakietu przeglądów później.",
        "textLine2": "{{validityTextLine}}",
        "textLine3": "Klikając „Anuluj”, dokończysz zakup pakietu przeglądów teraz.",
        "textLine4": "",
        "linkValidityDateFormat": "DD-MM-YYYY",
        // 1h
        "btnBack": "Anuluj"
      },
      // Alternative texts to "save" above when closeTabWarning prop is set.
      "closeTab": {
        // Not part of S&C 1.0
        // "title": "Desideri continuare in un altro momento?",
        // "textLine1": "Puoi concludere l'acquisto del piano di manutenzione per la tua {{productName}} più tardi. ",
        // "textLine2": "Cliccando su \"Procedi  in un altro momento\" riceverai un link al tuo indirizzo e-mail, tramite cui potrai riprendere il tuo acquisto esattamente dal punto in cui ll'avevi interrotto.",
        // "textLine3": "Tieni presente che il link sarà valido per sette giorni.",
        // "textLine4": "Clicca su \"Chiudi\"  per terminare la sessione.",
        // "btnBack": "Chiudi"
      },
      "form": {
        // 1b
        "customerEmail": "Adres e-mail",
        // 1c
        "customerPhoneCountryCode": "Kod kraju",
        // 1d
        "customerTelephoneNrTooltip": "Potrzebujemy Twojego numeru telefonu, aby wysłać kod weryfikacyjny, za pomocą którego możesz bezpiecznie dokończyć zakup.",
        // 1e
        "customerTelephoneNr": "Numer komórki",
        // 1f (static part)
        "dataProtection": "Zapoznałam(-em) się z ",
        // 1f (link label part)
        "dataProtectionLinkText": "Polityką przetwarzania przez Volkswagen Financial Services Polska Sp. z o.o. danych osobowych",
        // parameterized
        "dataProtectionLink": "/page/polityka-prywatnosci",
        // optional, e.g. PT
        "termsAndConditionsIntro": "",
        "termsAndConditionsLinkText": "",
        "termsAndConditionsLink": "",

        "dataProtectionEnding": ".",
        // ?
        "select": "",
      },
      // 1i
      "btnContinue": "Wyślij"
    },
    "mailDispatch": {
      // 2
      "heading": "Link został wysłany na Twój adres e-mail",
      "icon": "semantic-checkmark",
      // 2b
      "paragraph": "Na adres e-mail {{emailAddress}} została wysłana wiadomość z linkiem umożliwiającym sfinalizowanie zakupu pakietu przeglądów później. Sprawdź skrzynkę odbiorczą.",
      // 2c
      "sendLinkAgain": "Wyślij link ponownie",
      // 2d
      "buttonText": "Zamknij"
    },
    "tanAuthentication": {
      // 4
      "heading": "Witaj ponownie",
      // 4a
      "content": "Wprowadź kod, który wysłaliśmy do Ciebie SMS-em.",
      // 4b
      "inlineErrorMessage": "Nieprawidłowy kod. Spróbuj ponownie.",
      // 4c
      "formFieldHelperText": "KOD",
      // 4d
      "requestTanLink": "Wyślij wiadomość SMS z nowym kodem",
      // 4e
      "buttonText": "Zastosuj"
    },
    "linkExpiry": {
      // 3
      "heading": "Twój link wygasł",
      // 3a
      "paragraph": "Niestety link już wygasł.",
      // 3b
      "primaryButton": "Powrót"
    },
    "validation": {
      // TODO
      "required": "Pole jest wymagane",
      "minLength": "Błąd: Pole może zawierać minimum 3 znaków.",
      "maxLength": "Błąd: Pole może zawierać maksimum 15 znaków.",
      "invalidPhone": "Błąd: Pole wypełnione nieprawidłowo. Prosimy podać poprawny numer telefonu.",
      "invalidEmail": "Błąd: Pole wypełnione nieprawidłowo. Prosimy podać poprawny adres e-mail.",
      "invalidField": "Błąd: Pole wypełnione nieprawidłowo.",
    }
  }
}


