export function getTextsUK(data) {

  return {
    "continueLater": {
      // 0
      "floatingButtonTooltip": "Save and edit later",
      // ContinueLater texts when closeTabWarning prop is not set.
      "save": {
        // 1
        "title": "Save and edit later",
        // 1a
        "validityText": "Please note that the link will only be valid for {{data.linkValidityText}}.",
        "textLine1": "Please enter your email address and mobile phone number to save your details so that you can resume your purchase later. Any data you have provided so far will be saved, so you don't have to worry about filling the form out again. ",
        "textLine2": "You will receive a link via email that will allow you to pick up where you left off.",
        "textLine3": "{{validityTextLine}}",
        "textLine4": "",
        "linkValidityDateFormat": "DD/MM/YYYY hh:mm a",
        // 1h
        "btnBack": "Go back"
      },
      // Alternative texts to "save" above when closeTabWarning prop is set.
      "closeTab": {
        // Not part of S&C 1.0
        // "title": "Desideri continuare in un altro momento?",
        // "textLine1": "Puoi concludere l'acquisto del piano di manutenzione per la tua {{productName}} più tardi. ",
        // "textLine2": "Cliccando su \"Procedi  in un altro momento\" riceverai un link al tuo indirizzo e-mail, tramite cui potrai riprendere il tuo acquisto esattamente dal punto in cui ll'avevi interrotto.",
        // "textLine3": "Tieni presente che il link sarà valido per sette giorni.",
        // "textLine4": "Clicca su \"Chiudi\"  per terminare la sessione.",
        // "btnBack": "Chiudi"
      },
      "form": {
        // 1b
        "customerEmail": "Email address",
        // 1c
        "customerPhoneCountryCode": "Country Code",
        // 1d
        "customerTelephoneNrTooltip": "We need your mobile phone number so that we can send you a code via text to securely pick up where you left off",
        // 1e
        "customerTelephoneNr": "Mobile phone number",
        // 1f (static part)
        "dataProtection": "I'd like to receive news, updates and marketing messages",
        // 1f (link label part)
        "dataProtectionLinkText": "",
        // parameterized
        "dataProtectionLink": data.dataProtectionLink,
        // optional, e.g. PT
        "termsAndConditionsIntro": "",
        "termsAndConditionsLinkText": "",
        "termsAndConditionsLink": "",

        "dataProtectionEnding": "",
        // ?
        "select": "",
      },
      // 1i
      "btnContinue": "Send link to resume later"
    },
    "mailDispatch": {
      // 2
      "heading": "Thanks for saving your details! A link has been successfully sent to the email address provided.",
      "icon": "semantic-checkmark",
      // 2b
      "paragraph": "Your details have been saved and a link has been sent to the email address below. This will allow you to resume your purchase within {{data.linkValidityText}}.\nYour email: {{emailAddress}}",
      // 2c
      "sendLinkAgain": "Send link again",
      // 2d
      "buttonText": "Close"
    },
    "tanAuthentication": {
      // 4
      "heading": "Welcome",
      // 4a
      // use \n for multiple paragrahs
      "content": "\nThank you for choosing {{productName}}\n\nWe'd like to make sure it's really you before we can complete your application.\n\nPlease enter the code that we've just sent you via SMS.",
      // 4b
      "inlineErrorMessage": "This isn't the right number. Please try again.",
      // 4c
      "formFieldHelperText": "Your code",
      // 4d
      "requestTanLink": "Request new code via SMS",
      // 4e
      "buttonText": "Submit code"
    },
    "linkExpiry": {
      // 3
      "heading": "The link has expired",
      // 3a
      // use \n for multiple paragraphs
      "paragraph": "The link to continue your purchase has expired. Please start your purchase again.",
      // 3b 
      "primaryButton": "Go back"
    },
    "validation": {
      "required": "This is a mandatory field.",
      "minLength": "Please provide the minimum number of {{value}} characters.",
      "maxLength": "Maximum number of {{value}} characters exceeded.",
      "invalidPhone": "Please provide a valid phone number.",
      "invalidEmail": "Please provide a valid email address.",
      "invalidField": "Invalid character provided.",
    }
  }
}


