export function getTextsAU(data) {

  return {
    "continueLater": {
      // 0
      "floatingButtonTooltip": "Save for later",
      // ContinueLater texts when closeTabWarning prop is not set.
      "save": {
        // 1
        "title": "Would you like to save your application and continue  later?",
        // 1a
        "textLine1": "Please fill your details to save your application and continue it later. ",
        "textLine2": "We'll email you the link so that you can easily access your application again.",
        "textLine3": "",
        "textLine4": "",
        "linkValidityDateFormat": "DD/MM/YYYY hh:mm a",
        // 1h
        "btnBack": "Close and continue now"
      },
      // Alternative texts to "save" above when closeTabWarning prop is set.
      "closeTab": {
        // Not part of S&C 1.0
        // "title": "Desideri continuare in un altro momento?",
        // "textLine1": "Puoi concludere l'acquisto del piano di manutenzione per la tua {{productName}} più tardi. ",
        // "textLine2": "Cliccando su \"Procedi  in un altro momento\" riceverai un link al tuo indirizzo e-mail, tramite cui potrai riprendere il tuo acquisto esattamente dal punto in cui ll'avevi interrotto.",
        // "textLine3": "Tieni presente che il link sarà valido per sette giorni.",
        // "textLine4": "Clicca su \"Chiudi\"  per terminare la sessione.",
        // "btnBack": "Chiudi"
      },
      "form": {
        // 1b
        "customerEmail": "Your email address",
        // 1c
        "customerPhoneCountryCode": "Country Code",
        // 1d
        "customerTelephoneNrTooltip": "We need your mobile number so that we can SMS you a code to continue your application.",
        // 1e
        "customerTelephoneNr": "Your mobile number",
        // 1f (static part)
        "dataProtection": "I'd like to receive news, updates and marketing messages",
        // 1f (link label part)
        "dataProtectionLinkText": "",
        // parameterized
        "dataProtectionLink": data.dataProtectionLink,
        // optional, e.g. PT
        "termsAndConditionsIntro": "",
        "termsAndConditionsLinkText": "",
        "termsAndConditionsLink": "",

        "dataProtectionEnding": "",
        // ?
        "select": "",
      },
      // 1i
      "btnContinue": "Email link to continue later"
    },
    "mailDispatch": {
      // 2
      "heading": "The link has been successfully sent to your email address",
      "icon": "semantic-checkmark",
      // 2b
      "paragraph": "We've sent your link to {{emailAddress}}. Please use this link within the next 7 days.",
      // 2c
      "sendLinkAgain": "Re-send link",
      // 2d
      "buttonText": "Close"
    },
    "tanAuthentication": {
      // 4
      "heading": "Welcome back",
      // 4a
      // use \n for multiple paragrahs
      "content": "Please enter the code we just sent you via SMS.",
      // 4b
      "inlineErrorMessage": "This isn't the right number. Please try again.",
      // 4c
      "formFieldHelperText": "Your code",
      // 4d
      "requestTanLink": "Request new code via SMS",
      // 4e
      "buttonText": "Submit code"
    },
    "linkExpiry": {
      // 3
      "heading": "Your link is expired",
      // 3a
      // use \n for multiple paragraphs
      "paragraph": "Sorry, the link to continue your application has expired. You'll need to start your application again.",
      // 3b 
      "primaryButton": "Back to application"
    },
    "validation": {
      "required": "This is a mandatory field.",
      "minLength": "Please provide the minimum number of {{value}} characters.",
      "maxLength": "Maximum number of {{value}} characters exceeded.",
      "invalidPhone": "Please provide a valid phone number.",
      "invalidEmail": "Please provide a valid email address.",
      "invalidField": "Invalid character provided.",
    }
  }
}


