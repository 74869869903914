export function getTextsIE(data) {

  return {
    "continueLater": {
      // 0
      "floatingButtonTooltip": "Save and complete later",
      // ContinueLater texts when closeTabWarning prop is not set.
      "save": {
        // 1
        "title": "Would you like to save and continue later?",
        // 1a
        "validityText": "Please note that the link will only be valid until {{data.linkValidityText}}.",
        "textLine1": "Please confirm / enter your email address and mobile phone number to save your details so that you can resume your application later.",
        "textLine2": "Any data you have provided so far will be saved, so you don't have to worry about filling the form out again.",
        "textLine3": "You will receive a link via email that will allow you to pick up where you left off. ",
        "textLine4": "{{validityTextLine}}",
        "linkValidityDateFormat": "DD/MM/YYYY hh:mm a",
        // 1h
        "btnBack": "Go back"
      },
      // Alternative texts to "save" above when closeTabWarning prop is set.
      "closeTab": {
        // Not part of S&C 1.0
        // "title": "Desideri continuare in un altro momento?",
        // "textLine1": "Puoi concludere l'acquisto del piano di manutenzione per la tua {{productName}} più tardi. ",
        // "textLine2": "Cliccando su \"Procedi  in un altro momento\" riceverai un link al tuo indirizzo e-mail, tramite cui potrai riprendere il tuo acquisto esattamente dal punto in cui ll'avevi interrotto.",
        // "textLine3": "Tieni presente che il link sarà valido per sette giorni.",
        // "textLine4": "Clicca su \"Chiudi\"  per terminare la sessione.",
        // "btnBack": "Chiudi"
      },
      "form": {
        // 1b
        "customerEmail": "Email address",
        // 1c
        "customerPhoneCountryCode": "Country code",
        // 1d
        "customerTelephoneNrTooltip": "We need your mobile phone number so that we can send you a code via text to securely pick up where you left off.",
        // 1e
        "customerTelephoneNr": "Mobile phone number",
        // 1f (static part)
        "dataProtection": "Please confirm your details are correct",
        // 1f (link label part)
        "dataProtectionLinkText": "",
        // parameterized
        "dataProtectionLink": data.dataProtectionLink,
        // optional, e.g. PT
        "termsAndConditionsIntro": "",
        "termsAndConditionsLinkText": "",
        "termsAndConditionsLink": "",

        "dataProtectionEnding": "",
        // ?
        "select": "",
      },
      // 1i
      "btnContinue": "Save and continue later"
    },
    "mailDispatch": {
      // 2
      "heading": "Thanks for saving your details! ",
      "icon": "semantic-checkmark",
      // 2b
      "paragraph": "We have sent you a link to your email address {{emailAddress}}. This will allow you to continue your {{productName}} application until {{data.linkValidityText}}. Please check your mailbox.",
      // 2c
      "sendLinkAgain": "Send link again",
      // 2d
      "buttonText": "Close"
    },
    "mailDispatch-cic": {
      // 2
      "heading": "Confirm your contact details",
      "icon": "semantic-checkmark",
      // 2b
      "paragraph": "We will send an email to your address {{emailAddress}}.\nPlease follow the link and enter the code you will receive on your\nmobile phone.",
      // 2c
      "sendLinkAgain": "Receive the email again",
      // 2d
      "buttonText": "Amend Details",
      "buttonConfirmText": "Confirm Details"
    },
    "tanAuthentication": {
      // 4
      "heading": "Welcome back to your {{productName}} application.",
      // 4a
      // use \n for multiple paragrahs
      "content": "You should have received a one-time code via text message. Please enter it below to confirm your identity and continue your application.",
      // 4b
      "inlineErrorMessage": "The code entered is not recognised. Please try again.",
      // 4c
      "formFieldHelperText": "One-time code",
      // 4d
      "requestTanLink": "Request a new code",
      // 4e
      "buttonText": "Submit"
    },
    "tanAuthentication-cic": {
      // 4
      "heading": "Confirm your contact details",
      // 4a
      // use \n for multiple paragrahs
      "content": "Please enter the code send per SMS to **********6130:",
      // 4b
      "inlineErrorMessage": "error.",
      // 4c
      "formFieldHelperText": "Code",
      // 4d
      "requestTanLink": "Resend code",
      // 4e
      "buttonText": "Send code"
    },
    "linkExpiry": {
      // 3
      "heading": "The link has expired",
      // 3a
      // use \n for multiple paragraphs
      "paragraph": "The link to continue your purchase has expired. Please start your purchase again.",
      // 3b 
      "primaryButton": "Go back"
    },
    "validation": {
      "required": "This field is mandatory.",
      "minLength": "Please provide the minimum number of {{value}} characters.",
      "maxLength": "Maximum number of {{value}} characters exceeded.",
      "invalidPhone": "Please provide a valid phone number.",
      "invalidEmail": "Please provide a valid email address.",
      "invalidField": "Invalid character entered.",
    }
  }
}


