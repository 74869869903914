/**
 * Initial commit NM sourced by si-it-audi-client d4edc34
 * Stripped down/externalized parts.
 * 
 */ 
import fesRequest from '../../../common-frontend/src/services/api/fesRequest'

export function continueJourney(
  tan, 
  transactionId, 
  processResponse 
){
  return new Promise((resolve, reject) => {
    fesRequest({
      method: 'post',
      path: '/journey',
      secondaryPath: '/continue',
      transactionId,
      data: { tan },
      customHandleReject: true,
      resolveStatuses: [200, 401],
    }).then(
      response => {
        if (response.status === 401) {
          resolve('Invalid TAN')
          return
        }
        if (!response.token || !response.data) {
          reject(new Error('MISSING_DATA'))
          return
        }

        const { data, token } = response
        
        try {
          processResponse(data, token)
        } catch(e) {
          reject(e);
        }
        resolve(data)
      },
      error => {
        reject(error)
      }
    )
  })
}
