export function getTextsCZ(data) {

  return {
    "continueLater": {
      // 0
      "floatingButtonTooltip": "Uložit na později",
      // ContinueLater texts when closeTabWarning prop is not set.
      "save": {
        // 1
        "title": "Uložit a vrátit se později",
        // 1a
        "validityText": "Odkaz zaslaný na váš e-mail bude platný po dobu {{data.linkValidityText}}.",
        "textLine1": "Vyplňte vaše mobilní číslo a e-mailovou adresu, abyste mohli pokračovat s vyplněním formuláře později.",
        "textLine2": "{{validityTextLine}}",
        "textLine3": "",
        "textLine4": "",
        "linkValidityDateFormat": "DD.MM.YYYY HH:mm",
        // 1h
        "btnBack": "Vrátit se do žádosti"
      },
      // Alternative texts to "save" above when closeTabWarning prop is set.
      "closeTab": {
        // Not part of S&C 1.0
        // "title": "Desideri continuare in un altro momento?",
        // "textLine1": "Puoi concludere l'acquisto del piano di manutenzione per la tua {{productName}} più tardi. ",
        // "textLine2": "Cliccando su \"Procedi  in un altro momento\" riceverai un link al tuo indirizzo e-mail, tramite cui potrai riprendere il tuo acquisto esattamente dal punto in cui ll'avevi interrotto.",
        // "textLine3": "Tieni presente che il link sarà valido per sette giorni.",
        // "textLine4": "Clicca su \"Chiudi\"  per terminare la sessione.",
        // "btnBack": "Chiudi"
      },
      "form": {
        // 1b
        "customerEmail": "E-mail",
        // 1c
        "customerPhoneCountryCode": "Předvolba země",
        // 1d
        "customerTelephoneNrTooltip": "Vaše mobilní číslo potřebujeme proto, abychom vám na něj při návratu zaslali kód a ověřili tak, že se jedná skutečně o vás.",
        // 1e
        "customerTelephoneNr": "Mobilní číslo",
        // 1f (static part)
        "dataProtection": "Beru na vědomí ",
        // 1f (link label part)
        "dataProtectionLinkText": "Zásady zpracování osobních údajů",
        // parameterized
        "dataProtectionLink": data.dataProtectionLink || "/page/privacy-cookie-policy",
        // optional, e.g. PT
        "termsAndConditionsIntro": "",
        "termsAndConditionsLinkText": "",
        "termsAndConditionsLink": "",

        "dataProtectionEnding": ".",
        // ?
        "select": "",
      },
      // 1i
      "btnContinue": "Pošlete mi odkaz na e-mail"
    },
    "mailDispatch": {
      // 2
      "heading": "Úspěšně odesláno",
      "icon": "semantic-checkmark",
      // 2b
      "paragraph": "Na zadaný e-mail jsme vám poslali odkaz. Bude platný po dobu {{data.linkValidityText}} a slouží pro návrat do formuláře, abyste jej mohl dokončit.",
      // 2c
      "sendLinkAgain": "Zaslat link znovu",
      // 2d
      "buttonText": "Zavřít"
    },
    "tanAuthentication": {
      // 4
      "heading": "Vítejte zpět",
      // 4a
      "content": "Před tím, než se budete moct vrátit do fomuláře, je důležité zkontrolovat, že jste to skutečně vy.\nVaši identitu ověříme pomocí autorizačního kódu, který jsme vám právě zaslali v SMS na vámi předem zadané mobilní číslo.",
      // 4b
      "inlineErrorMessage": "Zadaný kód se neshoduje. Zkuste jej prosím vyplnit znovu.",
      // 4c
      "formFieldHelperText": "Vložte zaslaný kód",
      // 4d
      "requestTanLink": "Opětovně zaslat autorizační kód",
      // 4e
      "buttonText": "Potvrdit"
    },
    "linkExpiry": {
      // 3
      "heading": "Platnost odkazu vaší žádosti vypršela",
      // 3a
      "paragraph": "Z důvodu ochrany vašich dat má odkaz omezenou dobu platnosti. Pokud váš zájem o naše služby trvá, vyplňte prosím novou žádost.",
      // 3b
      "primaryButton": "Vrátit se na homepage"
    },
    "validation": {
      // TODO
      "required": "Bez toho se to bohužel neobejde.",
      "minLength": "Neplatný formát pole.",
      "maxLength": "Překročili jste povolený počet znaků.",
      "invalidPhone": "Neplatný formát pole.",
      "invalidEmail": "Neplatný formát E-mailu.",
      "invalidField": "Neplatný formát pole.",
    }
  }
}


