export function getTextsSE(data) {

  return {
    "continueLater": {
      // 0
      "floatingButtonTooltip": "NOT TRANSLATED YET. PLEASE CONTACT IT-STANDARDS TEAM!",
      // ContinueLater texts when closeTabWarning prop is not set.
      "save": {
        // 1
        "title": "NOT TRANSLATED YET. PLEASE CONTACT IT-STANDARDS TEAM!",
        // 1a
        "textLine1": "NOT TRANSLATED YET. PLEASE CONTACT IT-STANDARDS TEAM!",
        "textLine2": "",
        "textLine3": "",
        "textLine4": "",
        "linkValidityDateFormat": "DD/MM/YYYY hh:mm a",
        // 1h
        "btnBack": "NOT TRANSLATED YET. PLEASE CONTACT IT-STANDARDS TEAM!"
      },
      // Alternative texts to "save" above when closeTabWarning prop is set.
      "closeTab": {
        // Not part of S&C 1.0
        // "title": "Desideri continuare in un altro momento?",
        // "textLine1": "Puoi concludere l'acquisto del piano di manutenzione per la tua {{productName}} più tardi. ",
        // "textLine2": "Cliccando su \"Procedi  in un altro momento\" riceverai un link al tuo indirizzo e-mail, tramite cui potrai riprendere il tuo acquisto esattamente dal punto in cui ll'avevi interrotto.",
        // "textLine3": "Tieni presente che il link sarà valido per sette giorni.",
        // "textLine4": "Clicca su \"Chiudi\"  per terminare la sessione.",
        // "btnBack": "Chiudi"
      },
      "form": {
        // 1b
        "customerEmail": "NOT TRANSLATED YET. PLEASE CONTACT IT-STANDARDS TEAM!",
        // 1c
        "customerPhoneCountryCode": "NOT TRANSLATED YET. PLEASE CONTACT IT-STANDARDS TEAM!",
        // 1d
        "customerTelephoneNrTooltip": "NOT TRANSLATED YET. PLEASE CONTACT IT-STANDARDS TEAM!",
        // 1e
        "customerTelephoneNr": "NOT TRANSLATED YET. PLEASE CONTACT IT-STANDARDS TEAM!",
        // 1f (static part)
        "dataProtection": "NOT TRANSLATED YET. PLEASE CONTACT IT-STANDARDS TEAM!",
        // 1f (link label part)
        "dataProtectionLinkText": "NOT TRANSLATED YET. PLEASE CONTACT IT-STANDARDS TEAM!",
        // parameterized
        "dataProtectionLink": data.dataProtectionLink,
        // optional, e.g. PT
        "termsAndConditionsIntro": "",
        "termsAndConditionsLinkText": "",
        "termsAndConditionsLink": "",

        "dataProtectionEnding": "",
        // ?
        "select": "",
      },
      // 1i
      "btnContinue": "NOT TRANSLATED YET. PLEASE CONTACT IT-STANDARDS TEAM!"
    },
    "mailDispatch": {
      // 2
      "heading": "NOT TRANSLATED YET. PLEASE CONTACT IT-STANDARDS TEAM!",
      "icon": "semantic-checkmark",
      // 2b
      "paragraph": "NOT TRANSLATED YET. PLEASE CONTACT IT-STANDARDS TEAM!",
      // 2c
      "sendLinkAgain": "NOT TRANSLATED YET. PLEASE CONTACT IT-STANDARDS TEAM!",
      // 2d
      "buttonText": "NOT TRANSLATED YET. PLEASE CONTACT IT-STANDARDS TEAM!"
    },
    "tanAuthentication": {
      // 4
      "heading": "Välkommen tillbaka",
      // 4a
      // use \n for multiple paragrahs
      "content": "Ange PIN-koden du fick via SMS.",
      // 4b
      "inlineErrorMessage": "PIN-koden var inte korrekt. Försök igen",
      // 4c
      "formFieldHelperText": "PIN-koden",
      // 4d
      "requestTanLink": "Få en ny kod skickad via SMS",
      // 4e
      "buttonText": "Fortsätt"
    },
    "linkExpiry": {
      // 3
      "heading": "Länken är inte giltig längre",
      // 3a
      // use \n for multiple paragraphs
      "paragraph": "Länken är inte giltig längre, du behöver starta om din konfiguration från början",
      // 3b 
      "primaryButton": "Tillbaks till {{productName}} Oficial.se"
    },
    "validation": {
      "required": "Obligatoriskt",
      "minLength": "Ange minst minimum tecken",
      "maxLength": "Ange max maximum tecken",
      "invalidPhone": "Ange telefonnummer utan inledande nollor",
      "invalidEmail": "Ange e-postadress",
      "invalidField": "Ogiltigt tecken",
    }
  }
}


