/**
 * Initial commit NM sourced by si-it-audi-client d4edc34
 */
import React from 'react'
import { ContentSection, Heading, Paragraph, Button } from '@vwfs-bronson/bronson-react'
import { useTranslation } from 'react-i18next'

export const LinkExpiry = ({
  displayData,
  onButtonClick,
  hideButton,
  children
}) => {
  const { t } = useTranslation()
  const paragraphs = t('sf-sac:linkExpiry:paragraph', { productName: displayData.productName, transactionId: displayData.transactionId }).split("\n")
  const buttonText =t('sf-sac:linkExpiry:primaryButton', {productName: displayData.productName})
  return (
    <ContentSection pageWrapSize="small">
      <ContentSection.ComponentWrapper>
        <Heading level="2">{t('sf-sac:linkExpiry:heading')}</Heading>
        {paragraphs.map(p => <Paragraph>{p.split("\r").map((line, index) => <>{index > 0 ? <br/> : <></>}{line}</>)}</Paragraph>)}
        {children}
        {!hideButton &&
          <Button onClick={() => {
            if (onButtonClick) {
              onButtonClick()
            } else {
              console.warn(`no onButtonClick handler set in LinkExpiry component.`);
            }
          }}>
            {buttonText}
          </Button>
        }
      </ContentSection.ComponentWrapper>
    </ContentSection>
  )
}
