/**
 * Initial commit NM sourced by si-it-audi-client d4edc34
 * Original file name: services/brand/index.js
 * 
 * NOTE: TODO: Needs to be reworked to properly use the config approach, so the settings are derived from the config, not from process.env.
 */ 

 // TODO: This is journey specific and need to come from the journey's config's settings.
export const getBrandName = () => {
  return process.env.REACT_APP_WEBSITE_BRAND.toLowerCase()
}

// TODO: This is journey specific and need to come from the journey's config's settings.
export const getServiceBrandName = () => {
  const brand = getBrandName()
  switch (brand) {
    case 'vw6':
      return 'vw'
    default:
      return brand
  }
}

// TODO: This is journey specific and need to come from the journey's config's settings.
export const getBronsonBrand = (brand = getBrandName()) => {
  switch (brand) {
    case 'vwfs':
      return 'bluelabel'
    case 'vw':
    case 'vwcv':
    case 'vwn':
      return 'vw6'
    default:
      return brand
  }
}

export const getManufacturerName = () => {
  return getServiceBrandName()
}
