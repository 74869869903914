import { getTextsIT } from "./it.js" 
import { getTextsCZ } from "./cz.js" 
import { getTextsDE } from "./de.js" 
import { getTextsPL } from "./pl.js" 
import { getTextsPT } from "./pt.js" 
import { getTextsES } from "./es.js" 
import { getTextsFR } from "./fr.js"
import { getTextsIE } from "./ie.js"
import { getTextsAU } from "./au.js"
import { getTextsGR } from "./gr.js"
import { getTextsSE } from "./se.js"
import { getTextsUK } from "./uk.js"

// TODO: Proper approach with loading of specific locale only

export function continueLaterLocalization(data) {

  if (data.locale === 'it') {
    return getTextsIT(data);
  }
  if (data.locale === 'cz') {
    return getTextsCZ(data);
  }
  if (data.locale === 'de') {
    return getTextsDE(data);
  }
  if (data.locale === 'pl') {
    return getTextsPL(data);
  }
  if (data.locale === 'pt') {
    return getTextsPT(data);
  }
  if (data.locale === 'es') {
    return getTextsES(data);
  }
  if (data.locale === 'fr') {
    return getTextsFR(data);
  }
  if (data.locale === 'ie') {
    return getTextsIE(data);
  }
  if (data.locale === 'au') {
    return getTextsAU(data);
  }
  if (data.locale === 'gr') {
    return getTextsGR(data);
  }
  if (data.locale === 'se') {
    return getTextsSE(data);
  }
  if (data.locale === 'uk'){
    return getTextsUK(data)
  }
}