/**
 * Initial commit NM sourced by si-it-audi-client d4edc34
 */ 
import fesRequest from '../../../common-frontend/src/services/api/fesRequest'

export const generateTan = transactionId => {
  return new Promise((resolve, reject) => {
    fesRequest({
      method: 'post',
      path: '/tan',
      secondaryPath: '/generate',
      transactionId,
      customHandleReject: true,
    }).then(
      data => {
        resolve(data)
      },
      error => {
        reject(error)
      }
    )
  })
}
