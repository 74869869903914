/**
 * Initial commit NM sourced by si-it-audi-client d4edc34
 * Original name: Components/FormField/index.js
 * 
 */
import React from 'react'
import {
  FormField as BronsonReactFormField,
  ErrorMessage,
  InfoIcon,
} from '@vwfs-bronson/bronson-react'
import { Field } from 'formik'
import get from 'lodash/get'

export function BrFormField({
  render,
  name,
  labelElement = 'label',
  labelText,
  hint,
  infoIcon,
  notion,
  type = 'input',
  className,
  testId,
  noFloatingLabel,
}) {
  return (
    <Field name={name}>
      {({ field, form }) => (
        <BronsonReactFormField
          testId={testId}
          className={className}
          labelElement={labelElement || 'label'}
          labelText={
            <>
              {labelText}
              {notion && '*'}
              {infoIcon && <InfoIcon icon="semantic-info">{infoIcon}</InfoIcon>}
            </>
          }
          noFloatingLabel={noFloatingLabel}
          type={type}
          errorMessage={
            get(form.errors, name) && get(form.touched, name) ? (
              <ErrorMessage>
                {get(form.touched, name) && get(form.errors, name)}
              </ErrorMessage>
            ) : (
              ''
            )
          }
          hint={hint}
        >
          {render({
            name,
            error: !!(get(form.touched, name) && get(form.errors, name)),
            ...field,
          })}
        </BronsonReactFormField>
      )}
    </Field>
  )
}
