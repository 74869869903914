/**
 * Initial commit NM sourced by si-it-audi-client d4edc34
 */
import React, { useRef, useState } from 'react'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import {
  Modal,
  Input,
  ErrorMessage,
  Fieldset,
  Paragraph,
  Button,
  Form,
  Layout,
} from '@vwfs-bronson/bronson-react'
import { generateTan } from '../../../fes-api/generateTan'
import { continueJourney } from '../../../fes-api/continueJourney'
import { LinkExpiry } from '../linkExpiry'

//  import { handleError as handleFesError } from '../../../../../common-frontend/src/services/api/fesRequest'

import { BrFormField } from '../../FormField'
import { validationSchema } from './validationSchema'

export function TanAuthentication({
  version,
  visible,
  productName,
  onShow,
  onClickResendTan,
  customSendRequestTan,
  customSendRequestData,
  processResponse,
  transactionId,
  externalLinkExpiryMessage = false,
  linkExpiryContent, // optional rich content on link expiry page
  onLinkExpired,
  hideLinkExpiryButton = false,
  onError,
  navigateToStart,
  testShowLinkExpiry = false,
  tanButtonCooldown = false,
  isCic = false,
  align = ''
}) {

  if (version != 1) {
    throw "TanAuthentication version prop contains an unsupported version."
  }

  const [tanError, setTanError] = useState(false)
  const [linkExpiredMessage, setLinkExpiredMessage] = useState(false)
  const [isTanButtonDisabled, setTanButtonDisableStatus] = useState(false)
  const [currentCooldownInS, setCurrentCooldownInS] = useState(30)
  //const [transactionId, setTransactionId] = useState()
  const coolDownInMs = 30000
  const localizationSuffix = isCic ? '-cic' : ''
  const { t } = useTranslation()
  const formRef = useRef()
  React.useEffect(() => {
    if (transactionId) {
      /*
      const cid = qs(document.location.search, 'cid')
      setTransactionId(cid)
      requestTan(cid)
      */
      requestTan(transactionId)
      if (onShow) {
        onShow()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionId])

  const onValidate = (tan, setTanError, transactionId, processResponse) => {

    let promise 
    if (customSendRequestData) {
      promise = customSendRequestData(tan, transactionId, processResponse)
    } else {
      promise = continueJourney(tan, transactionId, processResponse)
    }
    promise.then(
      response => {
        if (!response?.transaction?.status) {
          setTanError(true)
        }
      },
      error => handleContinueError(error)
    )
  }

  const requestTan = transactionId => {

    let promise
    if (customSendRequestTan) {
      promise = customSendRequestTan(transactionId)
    } else {
      promise = generateTan(transactionId)
    }
    promise.then(
      () => null, // SMS sent
      error => handleContinueError(error)
    )
  }

  const handleContinueError = (error) => {
    if (
      error?.response?.status === 400 &&
      error?.response?.data?.message?.toLowerCase().includes('expired')
    ) {
      // history.push('/link-expired')
      setLinkExpiredMessage(true); 
      if (onLinkExpired) {
        onLinkExpired();
      } else {
        console.warn(`no onLinkExpired handler set in TanAuthentication component.`);
      }
    } else {
      if (onError) {
        onError(error);
      } else {
        console.warn(`no onError handler set in TanAuthentication component.`);
      }
      // handleFesError(error)
    }
  }

  let p = t(`sf-sac:tanAuthentication${localizationSuffix}:content`, {productName: productName})
  const pList = p.split('\n')
  p = pList.map(e => <Paragraph {...(align ? {align: align} : {})}>{e}</Paragraph>)

  return (
    <React.Fragment>
      {(!externalLinkExpiryMessage && (linkExpiredMessage || linkExpiryContent)) || testShowLinkExpiry ? (
        <LinkExpiry onButtonClick={navigateToStart} hideButton={hideLinkExpiryButton} displayData={{productName, transactionId}}>{linkExpiryContent}</LinkExpiry>
      ) :
      transactionId && (
        <Modal
          className="c-modal--stretch"
          testId="tanAuthenticationModal"
          id="tanAuthenticationModal"
          shown={visible}
          center
          title={t(`sf-sac:tanAuthentication${localizationSuffix}:heading`, { productName: productName })}
          buttonConfirmText={t(`sf-sac:tanAuthentication${localizationSuffix}:buttonText`)}
          onConfirm={async () => {
            await formRef.current.submitForm()
          }}
          hideCloseButton
        >
          {p}
          {tanError && (
            <ErrorMessage>
              {t(`sf-sac:tanAuthentication${localizationSuffix}:inlineErrorMessage`)}
            </ErrorMessage>
          )}
          <Layout center className="u-mb-small">
            <Layout.Item default="1/2" s="1/1">
              <Formik
                initialValues={{ tanAuthentication: '' }}
                validationSchema={validationSchema()}
                onSubmit={values =>
                  onValidate(
                    values.tanAuthentication,
                    setTanError,
                    transactionId,
                    processResponse,
                  )
                }
              >
                {formikProps => {
                  formRef.current = formikProps
                  return (
                    <Form floatingLabel onSubmit={formikProps.handleSubmit}>
                      <Fieldset>
                        <Fieldset.Row>
                          <BrFormField
                            name="tanAuthentication"
                            labelText={t(
                              `sf-sac:tanAuthentication${localizationSuffix}:formFieldHelperText`
                            )}
                            render={fieldProps => <Input {...fieldProps} />}
                          />
                        </Fieldset.Row>
                      </Fieldset>
                    </Form>
                  )
                }}
              </Formik>
            </Layout.Item>
          </Layout>
          <Button
            element="a"
            link
            icon="semantic-forward"
            iconSize="small"
            reversed
            to="#top"
            disabled = { tanButtonCooldown && isTanButtonDisabled }
            onClick={() => {
              setTanButtonDisableStatus(true)
              setCurrentCooldownInS(coolDownInMs/1000)
              let coolDownInterval = setInterval(()=>{
                setCurrentCooldownInS(prevCooldown => prevCooldown-1)                
              },1000)
              setTimeout(()=>{setTanButtonDisableStatus(false)
              clearInterval(coolDownInterval)},coolDownInMs)
              if (onClickResendTan) {
                onClickResendTan()
              }
              requestTan(transactionId)
            }}
          >
            {(!tanButtonCooldown || !isTanButtonDisabled) ? t(`sf-sac:tanAuthentication${localizationSuffix}:requestTanLink`): t('sf-sac:tanAuthentication:cooldownText') + currentCooldownInS + t('sf-sac:tanAuthentication:cooldownUnit')}
          </Button>
         
        </Modal>
      )}
    </React.Fragment>
  )
}

