/**
 * Note: Initial commit NM sourced by si-it-audi-client d4edc34
 *
 * Validations to share with frontend and backend
 *
 * Changes should be updated to both frontend and backend.
 * The validations should work with copy&paste for the most parts.
 * The only exception is ibanValidation that uses an asnyc check.
 * The check needs to be implemented separately.
 *
 * NOTE: Can't separate the i18n from these easilly beacuse of how the
 * forms work. Need to replace the messages in the backend to static
 * strings.
 */
import i18n from 'i18next'
import * as Yup from 'yup'

export const customerMobilePhoneNrValidation = (usePhoneCountryCode) => {

  return Yup.string()
    .when('continueLaterPhoneCountryCode', (continueLaterPhoneCountryCode, schema) => {

      let minLength = usePhoneCountryCode ? 3 : 4
      let maxLength = 15

      // if (continueLaterPhoneCountryCode === '+353') {
      if (i18n.language === 'ie') {
        // OSPDE-430: Phone number validations for IE localization (not the country code specifically)
        minLength = 9
        maxLength = 12
      }

      if (i18n.language === 'fr') {
        maxLength = 15
      }

      if (usePhoneCountryCode) {
        return Yup.string()
          // FLGFT-5350: Corrected no leading 0 or + when using country code field separatly.
          // DE: No leading zero as per GFTDE-2107. Note: On blur will remove leading zeros in all cases anyways.
          // FLGFT-5978: allow leading zeros for FLFR
          .matches(i18n.language === 'fr'? /^[0-9][0-9\s]*$/ : 
          i18n.language === 'de' && continueLaterPhoneCountryCode === '+49' ? /^1[0-9\s]*$/: /^[1-9][0-9\s]*$/, i18n.t(`sf-sac:validation:invalidPhone${continueLaterPhoneCountryCode === '+49' ? 'SpecialCheck' : ''}`))
          .min(minLength, i18n.t('sf-sac:validation:minLength', { value: minLength }))
          .max(maxLength, i18n.t('sf-sac:validation:maxLength', { value: maxLength }))
          .required(i18n.t('sf-sac:validation:required'))
      } else {
        return Yup.string()
          .matches(/^[+0-9][0-9\s]*$/, i18n.t('sf-sac:validation:invalidPhone'))
          .min(minLength, i18n.t('sf-sac:validation:minLength', { value: minLength }))
          .max(maxLength, i18n.t('sf-sac:validation:maxLength', { value: maxLength }))
          .required(i18n.t('sf-sac:validation:required'))
      }
    })
}

export const customerEmailValidation = () => Yup.string()
  .email(i18n.t('sf-sac:validation:invalidEmail'))
  .required(i18n.t('sf-sac:validation:required'))

export const agreeDataProtection = () => Yup.boolean().oneOf(
  [true],
  i18n.t('sf-sac:validation:required')
)
