/**
 * Initial commit NM sourced by si-it-audi-client d4edc34
 */
import i18n from 'i18next'
import * as Yup from 'yup'

export const validationSchema = () => Yup.object().shape({
  tanAuthentication: Yup.string()
    .matches(/^[0-9]{6}$/, i18n.t('sf-sac:validation:invalidField'))
    .required(i18n.t('sf-sac:validation:required')),
})
