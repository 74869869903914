export function getTextsFR(data) {

  return {
    "continueLater": {
      // 0
      "floatingButtonTooltip": "Enregistrer pour plus tard",
      // ContinueLater texts when closeTabWarning prop is not set.
      "save": {
        // 1
        "title": "Voulez-vous continuer plus tard?",
        // 1a
        "validityText": "Veuillez noter que le lien ne sera valide que jusqu’au {{data.linkValidityText}}!",
        "textLine1": "En cliquant sur \"Envoyer un lien et continuer plus tard\", vous recevrez un lien par e-mail, que vous pourrez utiliser pour procéder à votre achat sans perdre de données.",
        "textLine2": "{{validityTextLine}}",
        "textLine3": "Cliquez sur \"Fermer et continuer maintenant\" pour procéder à votre achat dès maintenant.",
        "textLine4": "",
        "linkValidityDateFormat": "DD.MM.YYYY",
        // 1h
        "btnBack": "Fermer et continuer maintenant" 
      },
      // Alternative texts to "save" above when closeTabWarning prop is set.
      "closeTab": {
        // Not part of S&C 1.0
        // "title": "Desideri continuare in un altro momento?",
        // "textLine1": "Puoi concludere l'acquisto del piano di manutenzione per la tua {{productName}} più tardi. ",
        // "textLine2": "Cliccando su \"Procedi  in un altro momento\" riceverai un link al tuo indirizzo e-mail, tramite cui potrai riprendere il tuo acquisto esattamente dal punto in cui ll'avevi interrotto.",
        // "textLine3": "Tieni presente che il link sarà valido per sette giorni.",
        // "textLine4": "Clicca su \"Chiudi\"  per terminare la sessione.",
        // "btnBack": "Chiudi"
      },
      "form": {
        // 1b
        "customerEmail": "Courriel",
        // 1c
        "customerPhoneCountryCode": "Indicatif pays",
        // 1d
        "customerTelephoneNrTooltip": "Nous avons besoin de votre numéro de téléphone mobile, car nous vous enverrons un code par SMS, afin que vous puissiez rejoindre votre achat en toute sécurité",
        // 1e
        "customerTelephoneNr": "Numéro de téléphone portable",
        // 1f (static part)
        "dataProtection": "En cochant cette case, je reconnais avoir pris connaissance et accepter sans réserve les ", 
        // 1f (link label part)
        "dataProtectionLinkText": "Conditions Générales d’Utilisation", 
        // parameterized
        "dataProtectionLink": data.dataProtectionLink || "/legal", // externally configured in ES
        // optional, e.g. PT
        "termsAndConditionsIntro": " et la ",
        "termsAndConditionsLinkText": "Politique de protection des données",
        "termsAndConditionsLink": data.termsAndConditionsLink || "/privacy", 

        "dataProtectionEnding": ".",
        // ?
        "select": "",
      },
      // 1i
      "btnContinue": "Envoyer le lien et continuer plus tard"
    },
    "mailDispatch": {
      // 2
      "heading": "Le lien a été envoyé avec succès à votre adresse e-mail",
      "icon": "semantic-checkmark",
      // 2b
      "paragraph": "Nous vous avons envoyé un lien pour continuer à acheter votre produit à un stade ultérieur à votre adresse e-mail {{emailAddress}}. Veuillez vérifier votre boîte e-mail.",
      // 2c
      "sendLinkAgain": "Envoyer à nouveau le lien",
      // 2d
      "buttonText": "Fermer"
    },
    "tanAuthentication": {
      // 4
      "heading": "Bienvenue",
      // 4a
      "content": "Veuillez saisir le code, que nous venons de vous envoyer par SMS.",
      // 4b
      "inlineErrorMessage": "Le code n’était pas correct. Veuillez réessayer.",
      // 4c
      "formFieldHelperText": "code",
      // 4d
      "requestTanLink": "Demandez un nouveau code par SMS",
      // 4e
      "buttonText": "Soumettre le code"
    },
    "linkExpiry": {
      // 3
      "heading": "Lien expiré",
      // 3a
      "paragraph": "Le lien pour continuer votre application a expiré. Veuillez recommencer votre demande.",
      // 3b
      "primaryButton": "Retour à l’achat"
    },
    "validation": {
      // TODO
      "required": "Il s’agit d’un champ obligatoire.",
      "minLength": "Veuillez fournir le nombre minimal de caractères.",
      "maxLength": "Nombre maximal de caractères dépassé.",
      "invalidPhone": "Veuillez renseigner un numéro de téléphone portable valide.",
      "invalidEmail": "Veuillez fournir une adresse e-mail valide.",
      "invalidField": "Caractère non valide fourni.",
    }
  }
}


