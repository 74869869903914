export function getTextsPT(data) {

  return {
    "continueLater": {
      // 0
      "floatingButtonTooltip": "Gravar e voltar mais tarde",
      // ContinueLater texts when closeTabWarning prop is not set.
      "save": {
        // 1
        "title": "Deseja continuar o processo mais tarde?",
        // 1a
        "textLine1": "Ao clicar \"enviar link e continuar mais tarde\" receberá um link no seu email, que poderá usar para continuar o processo mais tarde sem perder a informação que já preencheu.",
        "textLine2": "Este link estará disponível durante 10 dias!",
        "textLine3": "Clique em \"Fechar e continuar\" para avançar agora.",
        "textLine4": "",
        "linkValidityDateFormat": "DD/MM/YYYY",
        // 1h
        "btnBack": "Fechar e continuar agora"
      },
      // Alternative texts to "save" above when closeTabWarning prop is set.
      "closeTab": {
        // Not part of S&C 1.0
        // "title": "Desideri continuare in un altro momento?",
        // "textLine1": "Puoi concludere l'acquisto del piano di manutenzione per la tua {{productName}} più tardi. ",
        // "textLine2": "Cliccando su \"Procedi  in un altro momento\" riceverai un link al tuo indirizzo e-mail, tramite cui potrai riprendere il tuo acquisto esattamente dal punto in cui ll'avevi interrotto.",
        // "textLine3": "Tieni presente che il link sarà valido per sette giorni.",
        // "textLine4": "Clicca su \"Chiudi\"  per terminare la sessione.",
        // "btnBack": "Chiudi"
      },
      "form": {
        // 1b
        "customerEmail": "Email",
        // 1c
        "customerPhoneCountryCode": "Código do país",
        // 1d
        "customerTelephoneNrTooltip": "Para voltar a aceder enviaremos uma mensagem com um código para o seu telemóvel, para que volte com segurança aos seus dados.",
        // 1e
        "customerTelephoneNr": "Contacto telefónico",
        // 1f (static part)
        "dataProtection": "Confirmo que li e aceito a ",
        // 1f (link label part)
        "dataProtectionLinkText": "Política de Privacidade",
        // parameterized
        "dataProtectionLink": "/page/dataPrivacy",
        // optional, e.g. PT
        "termsAndConditionsIntro": " e os ",
        "termsAndConditionsLinkText": "Termos e Condições",
        "termsAndConditionsLink": "/page/legalTerms",

        "dataProtectionEnding": ".",
        // ?
        "select": "",
      },
      // 1i
      "btnContinue": "Enviar link e continuar mais tarde"
    },
    "mailDispatch": {
      // 2
      "heading": "O link foi enviado com sucesso para o seu email",
      "icon": "semantic-checkmark",
      // 2b
      "paragraph": "Enviámos para o seu email {{emailAddress}} o link para continuar o processo mais tarde. Por favor verifique o seu email.",
      // 2c
      "sendLinkAgain": "Enviar link novamente",
      // 2d
      "buttonText": "Fechar"
    },
    "tanAuthentication": {
      // 4
      "heading": "Bem-vindo de volta!",
      // 4a
      "content": "Por favor introduza o código enviado.",
      // 4b
      "inlineErrorMessage": "Código incorreto. Tente novamente.",
      // 4c
      "formFieldHelperText": "Introduza o código",
      // 4d
      "requestTanLink": "Enviar código novamente",
      // 4e
      "buttonText": "Validar código"
    },
    "linkExpiry": {
      // 3
      "heading": "O link expirou!",
      // 3a
      "paragraph": "O link expirou. Por favor inicie novamente o processo.",
      // 3b
      "primaryButton": "Voltar"
    },
    "validation": {
      // TODO
      "required": "Campo de preenchimento obrigatório.",
      "minLength": "Por favor, insira o número mínimo de caracteres.",
      "maxLength": "Excedeu o número máximo de caracteres.",
      "invalidPhone": "Não são permitidos zeros duplos à esquerda.",
      "invalidEmail": "Por favor, forneça um endereço de email válido.",
      "invalidField": "Introduziu um caracter inválido.",
    }
  }
}


