/**
 * Initial commit NM sourced by si-it-audi-client d4edc34
 * Original name: continueLater.js
 * 
 * Note: The original si-it code causes some React warnings. TODO resolve.
 * 
 */
import React from 'react'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import {
  Modal,
  ContentSection,
  Fieldset,
  Layout,
  Input,
  Checkbox,
  Form,
  Select,
  Paragraph,
} from '@vwfs-bronson/bronson-react'
import { connect } from 'react-redux' // TODO
import moment from 'moment'
import { BrFormField } from '../FormField'
import { MailDispatch } from './mailDispatch'
import { ContinueLaterValidationSchema } from './validation'
import { getFormData, getDataModels } from '../../../../common-frontend/src/services/redux/features/form'
import { countryCodeList } from './countryCodeList'

export const ContinueLater = ({
  version,
  productName,
  linkValidityText,
  linkValidityDate, // optional to linkValidityText, date and time will be rendered according to localized format.
  email,
  phoneCountryCode,
  hidePhoneCountryCode = false,
  phoneNo,
  /* Note: This only sets <input readonly>, assuming it is user input anyway from a previous screen. 
   *       It is not protecting from frontend manipulation.
   */
  readOnlyWhenPrefilled = false,
  dataProtection, // initial value of checkbox
  hideDataProtectionCheckbox = false,
  dataProtectionLink, // optional, otherwise part of the localization
  showModal,
  onShow,
  onCancel,
  transactionStatus,
  dataModels,
  onContinueLaterSubmit,
  onSendAgainSubmit,
  additionalText = "",
  customLegalEntity,
  additionalDataProtectionText,
  hideValidityText = false,
  customEmailAddress,
  showRequiredFieldsHint = false
}) => {

  if (version != 1) {
    throw "ContinueLater version prop contains an unsupported version."
  }

  const { t } = useTranslation()

  let customerCountryCodeList = dataModels.customerCountryCodeList
  if (!customerCountryCodeList) {
    customerCountryCodeList = countryCodeList;
  }

  const [showMailDispatched, setShowMailDispatched] = React.useState(false)
  const [mergedData, setMergedData] = React.useState()
  const [prefilledPhone, setPrefilledPhone] = React.useState()
  const [prefilledMail, setPrefilledMail] = React.useState()
  const [
    prefilledPhoneCountryCode,
    setPrefilledPhoneCountryCode,
  ] = React.useState()
  const formRef = React.useRef()
  const textKey = showModal.closeTabWarning ? 'closeTab' : 'save'

  const linkValidityDisplayText = linkValidityText || moment(linkValidityDate).format(t('sf-sac:continueLater:save:linkValidityDateFormat'))
  const displayDataStatic = {
    productName,
    linkValidityText: linkValidityDisplayText,
    additionalText,
  }
  const validityTextLine = t('sf-sac:continueLater:save:validityText', {data:displayDataStatic})
  const texts = t(`sf-sac:continueLater:${textKey}`, { returnObjects: true, data: displayDataStatic,validityTextLine: hideValidityText ? '' : validityTextLine })
  const visible = showModal.showContinueLater || showModal.closeTabWarning
  const requiredFieldsHint = t('sf-sac:continueLater:form:requiredFieldsHint', '')
  React.useEffect(() => {
    if (visible) {
      if (onShow) {
        onShow()
      }

      // prefill email and phone with previously entered data
      if (email) {
        formRef.current.setFieldValue('continueLaterEmail', email)
        setPrefilledMail(email)
      }
      if (phoneNo) {
        formRef.current.setFieldValue('continueLaterPhone', phoneNo)
        setPrefilledPhone(phoneNo)
      }
      if (phoneCountryCode) {
        formRef.current.setFieldValue(
          'continueLaterPhoneCountryCode',
          phoneCountryCode
        )
        setPrefilledPhoneCountryCode(phoneCountryCode)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  return (
    <>
      {showMailDispatched && (
        <MailDispatch
          visible
          submitData={mergedData}
          displayData={{
            productName: productName,
            emailAddress: formRef.current.values.continueLaterEmail,
            linkValidityText: linkValidityDisplayText
          }}
          onClose={() => setShowMailDispatched(false)}
          onSendAgainSubmit={onSendAgainSubmit}
        />
      )}
      <Modal
        testId="continueLaterModal"
        id="continueLaterModal"
        title={texts.title}
        center
        className="c-modal--stretch"
        shown={visible && !showMailDispatched}
        onCancel={() => {
          onCancel()
        }}
        onConfirm={async () => {
          await formRef.current.submitForm()
        }}
        buttonCancelText={t(`sf-sac:continueLater:${textKey}:btnBack`)}
        buttonConfirmText={t('sf-sac:continueLater:btnContinue')}
        hideCloseButton
      >
        <Introduction texts={texts} />
        <Formik
          initialValues={{
            continueLaterEmail: email || '',
            continueLaterPhone: phoneNo || '',
            continueLaterPhoneCountryCode: phoneCountryCode || '',
            continueLaterDataProtection: dataProtection,
          }}
          validationSchema={ContinueLaterValidationSchema(!hidePhoneCountryCode)}
          onSubmit={values => {
            setMergedData(values)
            onContinueLaterSubmit(
              values,
              showModal,
              setShowMailDispatched,
              transactionStatus,
            )
          }}
        >
          {formikProps => {
            const { setFieldValue, setFieldTouched } = formikProps
            formRef.current = formikProps
            return (
              <ContentSection>
                <Form floatingLabel>
                  <Fieldset>
                    <CustomerEmail
                      t={t}
                      prefilledMail={prefilledMail}
                      readOnly={readOnlyWhenPrefilled && prefilledMail}
                    />
                    {!hidePhoneCountryCode &&
                      <CustomerMobilePhoneCountryCode
                        t={t}
                        prefilledPhoneCountryCode={prefilledPhoneCountryCode}
                        customerCountryCodeList={customerCountryCodeList}
                        readOnly={readOnlyWhenPrefilled && prefilledPhoneCountryCode}
                      />
                    }
                    <CustomerMobilePhoneNr
                      t={t}
                      prefilledPhone={prefilledPhone}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      readOnly={readOnlyWhenPrefilled && prefilledPhone}
                      usePhoneCountryCode={!hidePhoneCountryCode}
                    />
                    {!hideDataProtectionCheckbox &&
                      <DataProtection
                        t={t}
                        formikProps={formikProps}
                        dataProtectionLink={dataProtectionLink}
                        customLegalEntity={customLegalEntity}
                        additionalDataProtectionText={additionalDataProtectionText}
                        customEmailAddress={customEmailAddress}
                      />
                    }
                    {
                      showRequiredFieldsHint && requiredFieldsHint &&
                      <Paragraph align='left'>
                      {requiredFieldsHint}
                      </Paragraph>
                    }
                  </Fieldset>
                </Form>
              </ContentSection>
            )
          }}
        </Formik>
      </Modal>
    </>
  )
}

const onChange = async (e, formikProps) => {
  const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
  await formikProps.setFieldValue(e.target.name, value)
}

const FormFieldWrapper = ({ children }) => (
  <Fieldset.Row>
    <Layout>
      <Layout.Item default="1/1" s="1/1">
        {children}
      </Layout.Item>
    </Layout>
  </Fieldset.Row>
)

const Introduction = ({ texts }) => (
  // Note: This is multiline/br style derived from si-it-audi-client d4edc34, TODO refactor it to an appropriate approach.
  <>
    {texts.textLine1 !== '' && <>{texts.textLine1}<br/><br/></>}
    {texts.textLine2 !== '' && <>{texts.textLine2}<br/><br/></>}
    {texts.textLine3 !== '' && <>{texts.textLine3}<br/><br/></>}
    {texts.textLine4 !== '' && <>{texts.textLine4}<br/><br/></>}
  </>
)

const CustomerEmail = ({ t, prefilledMail, readOnly }) => (
  <FormFieldWrapper>
    <BrFormField
      name="continueLaterEmail"
      notion
      labelText={t('sf-sac:continueLater:form:customerEmail')}
      render={fieldProps => (
        <Input
          defaultValue={prefilledMail}
          readOnly={readOnly}
          {...fieldProps}
        />
      )}
    />
  </FormFieldWrapper>
)

const CustomerMobilePhoneNr = ({ t, prefilledPhone, setFieldValue, setFieldTouched, readOnly, usePhoneCountryCode }) => (
  <FormFieldWrapper>
    <BrFormField
      name="continueLaterPhone"
      notion
      labelText={t('sf-sac:continueLater:form:customerTelephoneNr')}
      infoIcon={t('sf-sac:continueLater:form:customerTelephoneNrTooltip')}
      render={fieldProps => (
        <Input
          defaultValue={prefilledPhone}
          {...fieldProps}
          readOnly={readOnly}
          onBlur={e => {
            if (usePhoneCountryCode && !!e.target.value) {
              setFieldValue(
                'continueLaterPhone',
                e.target.value.replace(/^(0*)(\d*)/, '$2')
              )
            }
            setFieldTouched('continueLaterPhone')
          }}
        />
      )}
    />
  </FormFieldWrapper>
)

const CustomerMobilePhoneCountryCode = ({
  // eslint-disable-next-line no-unused-vars
  t,
  prefilledPhoneCountryCode,
  customerCountryCodeList,
  readOnly,
}) => (
  <FormFieldWrapper>
    {customerCountryCodeList && (
      <BrFormField
        name="continueLaterPhoneCountryCode"
        notion
        labelText={t('sf-sac:continueLater:form:customerPhoneCountryCode')}
        defaultValue={prefilledPhoneCountryCode}
        noFloatingLabel
        render={fieldProps => (
          <Select
            values={customerCountryCodeList}
            readOnly={readOnly}
            {...fieldProps}
          >
            {customerCountryCodeList.map(item => (
              <Select.Item
                key={`${item.value}${item.label}`}
                value={item.value}
              >
                {item.label}
              </Select.Item>
            ))}
          </Select>
        )}
      />
    )}
  </FormFieldWrapper>
)

const ToCBlock = ({ t }) => {

  let key = `sf-sac:continueLater:form:termsAndConditionsLinkText`
  if (!t(key) || t(key).length === 0) {
    return <></>
  }
  
  return (
    <>
      {t('sf-sac:continueLater:form:termsAndConditionsIntro')}
      <a
        href={t('sf-sac:continueLater:form:termsAndConditionsLink')}
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('sf-sac:continueLater:form:termsAndConditionsLinkText')}
      </a>
    </>
  )
}

const DataProtection = ({ t, formikProps, dataProtectionLink, customLegalEntity,additionalDataProtectionText, customEmailAddress }) => {
  let standardLegalEntity = t('sf-sac:continueLater:form:standardLegalEntity')
  let legalEntity = customLegalEntity || standardLegalEntity

  let standardEmailAddress = t('sf-sac:continueLater:form:standardEmailAddress')
  let contactEmailAddress = customEmailAddress || standardEmailAddress
  
  return(
  <FormCheckBox
    name="continueLaterDataProtection"
    required
    formikProps={formikProps}
  >
    {t('sf-sac:continueLater:form:dataProtection')}
    <a
      href={dataProtectionLink || t('sf-sac:continueLater:form:dataProtectionLink')}
      target="_blank"
      rel="noopener noreferrer"
    >
      {t('sf-sac:continueLater:form:dataProtectionLinkText')}
    </a>
    <ToCBlock t={t}></ToCBlock>
    {t('sf-sac:continueLater:form:dataProtectionEnding', {legalEntity, contactEmailAddress})}
    
    {additionalDataProtectionText && <><br/><br/> {additionalDataProtectionText} </>}
  </FormCheckBox>)
}

const FormCheckBox = ({ name, required = false, formikProps, children }) => (
  <FormFieldWrapper>
    <BrFormField
      name={name}
      type="checkbox"
      render={() => (
        <Checkbox
          name={name}
          id={name}
          testId={name}
          onChange={e => onChange(e, formikProps)}
        >
          {children}
          {required && '*'}
        </Checkbox>
      )}
    />
  </FormFieldWrapper>
)

const mapStateToProps = state => {
  return {
    journeyData: getFormData(state),
    dataModels: getDataModels(state),
  }
}

export const ContinueLater1 = connect(mapStateToProps)(ContinueLater)
