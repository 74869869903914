/**
 * Initial commit NM sourced by si-it-audi-client d4edc34
 */
import * as Yup from 'yup'
import * as ValidationFunction from './validation'

export const ContinueLaterValidationSchema = (usePhoneCountryCode) => Yup.object().shape({
  continueLaterEmail: ValidationFunction.customerEmailValidation(),
  continueLaterPhone: ValidationFunction.customerMobilePhoneNrValidation(usePhoneCountryCode),
  continueLaterDataProtection: ValidationFunction.agreeDataProtection(),
})
