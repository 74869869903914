export function getTextsIT(data) {

  return {
    "continueLater": {
      // 0
      "floatingButtonTooltip": "salva per dopo",
      // ContinueLater texts when closeTabWarning prop is not set.
      "save": {
        // 1
        "title": "Desideri continuare in un altro momento?",
        // 1a
        "validityText": "Tieni presente che il link sarà valido per {{data.linkValidityText}}.",
        "textLine1": "Puoi finire di compilare la tua richiesta in un secondo momento.",
        "textLine2": "Cliccando su \"Continua in un altro momento\" riceverai un link al tuo indirizzo e-mail, tramite cui potrai riprendere il tuo acquisto esattamente dal punto in cui l'avevi interrotto.",
        "textLine3": "{{validityTextLine}}",
        "textLine4": "Cliccando su \"Chiudi e continua con l'acquisto\" tornerai alla schermata precedente",
        "linkValidityDateFormat": "DD.MM.YYYY HH:mm",
        // 1h
        "btnBack": "Chiudi e continua con l'acquisto"
      },
      // Alternative texts to "save" above when closeTabWarning prop is set.
      "closeTab": {
        // Not part of S&C 1.0
        // "title": "Desideri continuare in un altro momento?",
        // "textLine1": "Puoi concludere l'acquisto del piano di manutenzione per la tua {{productName}} più tardi. ",
        // "textLine2": "Cliccando su \"Procedi  in un altro momento\" riceverai un link al tuo indirizzo e-mail, tramite cui potrai riprendere il tuo acquisto esattamente dal punto in cui ll'avevi interrotto.",
        // "textLine3": "Tieni presente che il link sarà valido per sette giorni.",
        // "textLine4": "Clicca su \"Chiudi\"  per terminare la sessione.",
        // "btnBack": "Chiudi"
      },
      "form": {
        // 1b
        "customerEmail": "Inserisci il tuo indirizzo mail",
        // 1c
        "customerPhoneCountryCode": "Prefisso nazionale",
        // 1d
        "customerTelephoneNrTooltip": "Abbiamo bisogno del tuo numero di telefono per poter verificare la tua identità. Ti verrà inviato un codice via SMS. Una volta identificato potrai continuare con il tuo acquisto in tutta sicurezza.",
        // 1e
        "customerTelephoneNr": "Numero di telefono cellulare",
        // 1f (static part)
        "dataProtection": "Dichiara di aver letto e preso visione ",
        // 1f (link label part)
        "dataProtectionLinkText": "dell’informativa privacy",
        // parameterized
        "dataProtectionLink": data.dataProtectionLink || "/page/privacy-cookie-policy",
        // optional, e.g. PT
        "termsAndConditionsIntro": "",
        "termsAndConditionsLinkText": "",
        "termsAndConditionsLink": "",

        "dataProtectionEnding": "",
        // ?
        "select": "Selezionare",
      },
      // 1i
      "btnContinue": "Procedi in un altro momento"
    },
    "mailDispatch": {
      // 2
      "heading": "Il link per continuare con il tuo acquisto è stato inviato correttamente al tuo indirizzo e-mail",
      "icon": "semantic-checkmark",
      // 2b
      "paragraph": "Ti abbiamo inviato un link per continuare a compilare la tua richiesta all'indirizzo e-mail {{emailAddress}}. Ti invitiamo a controllare la tua casella di posta elettronica.",
      // 2c
      "sendLinkAgain": "Invia nuovamente il link",
      // 2d
      "buttonText": "Chiudi"
    },
    "tanAuthentication": {
      // 4
      "heading": "Bentornato",
      // 4a
      "content": "Inserisci il PIN che è stato inviato tramite SMS al tuo numero di telefono",
      // 4b
      "inlineErrorMessage": "Il PIN non è corretto, inserisci il PIN corretto o richiedi un nuovo codice cliccando sull'apposito pulsante.",
      // 4c
      "formFieldHelperText": "PIN",
      // 4d
      "requestTanLink": "Richiedi un nuovo PIN",
      // 4e
      "buttonText": "Inoltra Codice"
    },
    "linkExpiry": {
      // 3
      "heading": "Link scaduto",
      // 3a
      "paragraph": "Siamo spiacenti ma il link per terminare l'inserimento della tua richiesta è scaduto. Puoi tornare all'home page e inserire una nuova richiesta.",
      // 3b
      "primaryButton": "Torna all'home page"
    },
    "validation": {
      "required": "Campo necessario. Si prega di inserire le informazioni richieste.",
      "minLength": "Inserisci almeno {{value}} caratteri.",
      "maxLength": "Inserisci al massimo {{value}} caratteri.",
      "invalidPhone": "Inserisci un numero di telefono valido compreso di prefisso.",
      "invalidEmail": "Inserisci un indirizzo email valido.",
      "invalidField": "Si prega di compilare tutti i campi.",
    }
  }
}


