export function getTextsES(data) {

  return {
    "continueLater": {
      // 0
      "floatingButtonTooltip": "Guardar y continuar más tarde",
      // ContinueLater texts when closeTabWarning prop is not set.
      "save": {
        // 1
        "title": "¿Te gustaría continuar más tarde?",
        // 1a
        "validityText": "Recuerda que este enlace tiene una validez hasta {{data.linkValidityText}}.",
        "textLine1": "Clicando en \"enviar enlace y continuar más tarde\" recibirás un email con un enlace para continuar con tu solicitud sin perder ningún dato.",
        "textLine2": "{{validityTextLine}}",
        "textLine3": "Clica en \"cerrar y continuar\" para seguir con tu solicitud.",
        "textLine4": "{{data.additionalText}}",
        "linkValidityDateFormat": "DD.MM.YYYY HH:mm",
        // 1h
        "btnBack": "Cerrar y continuar"
      },
      // Alternative texts to "save" above when closeTabWarning prop is set.
      "closeTab": {
        // Not part of S&C 1.0
        // "title": "Desideri continuare in un altro momento?",
        // "textLine1": "Puoi concludere l'acquisto del piano di manutenzione per la tua {{productName}} più tardi. ",
        // "textLine2": "Cliccando su \"Procedi  in un altro momento\" riceverai un link al tuo indirizzo e-mail, tramite cui potrai riprendere il tuo acquisto esattamente dal punto in cui ll'avevi interrotto.",
        // "textLine3": "Tieni presente che il link sarà valido per sette giorni.",
        // "textLine4": "Clicca su \"Chiudi\"  per terminare la sessione.",
        // "btnBack": "Chiudi"
      },
      "form": {
        // 1b
        "customerEmail": "Tu dirección de email",
        // 1c
        "customerPhoneCountryCode": "Código de país",
        // 1d
        "customerTelephoneNrTooltip": "Necesitaremos tu número de teléfono movil para enviarte por sms el código de acceso y así poder continuar de forma segura con tu solicitud en cualquier momento",
        // 1e
        "customerTelephoneNr": "Número de teléfono movil",
        // 1f (static part)
        "dataProtection": "He leído y acepto la ", 
        // 1f (link label part)
        "dataProtectionLinkText": "política de protección de datos", 
        // parameterized
        "dataProtectionLink": "", // externally configured in ES
        // optional, e.g. PT
        "termsAndConditionsIntro": "",
        "termsAndConditionsLinkText": "",
        "termsAndConditionsLink": "", 

        "dataProtectionEnding": ". Te informamos que para la gestión de tu solicitud de información los datos personales aquí facilitados (mail y teléfono) serán tratados por {{legalEntity}} con domicilio social en Avda. de Bruselas, nº 34, 28108, Alcobendas (Madrid). Dicho tratamiento viene legitimado por tu solicitud. Para ejercitar los derechos previstos en la normativa de protección de datos (acceso, rectificación o supresión, oposición), puedes escribir a: {{contactEmailAddress}}. Además, también puedes contactar con nuestro Delegado de Protección de Datos en la dirección: DPO.es@vwfs.com. Los datos personales tratados para el acceso a la solicitud en tramitación se conservarán durante un plazo de 90 días naturales.",
        "standardLegalEntity": "Volkswagen Renting S.A. (VWR)",
        "standardEmailAddress": "clientes.renting@vwfs.com",
        "additionalDataProtectionText": "{{additionalDataProtectionText}}",
        // ?
        "select": "",
      },
      // 1i
      "btnContinue": "Enviar enlace y continuar más tarde"
    },
    "mailDispatch": {
      // 2
      "heading": "El enlace ha sido enviado correctamente a tu dirección de correo",
      "icon": "semantic-checkmark",
      // 2b
      "paragraph": "Hemos enviado un mail a la siguiente dirección {{emailAddress}} con el enlace para que puedas continuar con tu solicitud en otro momento. Por favor comprueba tu correo.",
      // 2c
      "sendLinkAgain": "Enviar enlace de nuevo",
      // 2d
      "buttonText": "Cerrar"
    },
    "tanAuthentication": {
      // 4
      "heading": "Hola de nuevo",
      // 4a
      "content": "Por favor introduce el código TAN que acabas de recibir en tu móvil",
      // 4b
      "inlineErrorMessage": "El código TAN no es correcto, por favor inténtalo de nuevo",
      // 4c
      "formFieldHelperText": "Código PIN",
      // 4d
      "requestTanLink": "Solicita un nuevo código TAN por sms",
      // 4e
      "buttonText": "Enviar código TAN"
    },
    "linkExpiry": {
      // 3
      "heading": "Este enlace ha caducado",
      // 3a
      "paragraph": "El enlace para continuar con tu solicitud ha caducado. Por favor inicia de nuevo tu solicitud.",
      // 3b
      "primaryButton": "Volver a la solicitud"
    },
    "validation": {
      // TODO
      "required": "Este dato es obligatorio para continuar",
      "minLength": "El dato facilitado no alcanza el número mínimo de caracteres",
      "maxLength": "Número máximo de caracteres excedido",
      "invalidPhone": "Por favor introduzca un número de teléfono válido",
      "invalidEmail": "Por favor introduzca un email válido",
      "invalidField": "Se ha introducido un carácter no válido",
    }
  }
}


