/**
 * Initial commit NM sourced by si-it-audi-client d4edc34
 * 
 * @param {string} method - ("POST")
 * @param {string} path - ("/tan")
 * @param {string} [transactionId] - ID for path parameter
 * @param {string} [data] - request body for POST, PUT etc.
 * @param {string} [secondaryPath] ("/generate")
 * @param {string} [applyProductPath] - add brand, product etc to path (default: true)
 * @param {string} [resolveStatuses] - http statuses for promise resolve (default: [200])
 * @param {string} [onError] - method for error handling
 * @param {string} [customHandleReject] - if false, onError is used for Promise.reject (default: false)
 */
import { getServiceBrandName } from './brandLegacy'
// import { history } from 'services/routing'
import { axios } from '.'
/*
import { createBrowserHistory } from 'history'

// TODO: This is temporary copy, as both this and the import need to be moved out of this fe layer.
const history = createBrowserHistory({
  basename: process.env.REACT_APP_BASE_PATH,
})
*/

// TODO: This needs to be reworked to the config approach
const {
  REACT_APP_COUNTRYCODE,
  REACT_APP_PRODUCT,
  REACT_APP_ENTRYPOINT,
} = process.env

const DEFAULT_RESOLVE_STATUSES = [200]

const fesRequest = props => {
  const {
    transactionId,
    method,
    data,
    path: primaryPath,
    secondaryPath = '',
    applyProductPath = true,
    resolveStatuses = DEFAULT_RESOLVE_STATUSES,
    onError, // = handleError,
    customHandleReject = false,
  } = props

// TODO: This needs to be reworked to properly use the config approach
const productPath = applyProductPath
    ? `/${REACT_APP_PRODUCT}/${REACT_APP_COUNTRYCODE}/${getServiceBrandName()}/${REACT_APP_ENTRYPOINT}`
    : ''
  const transactionPath = transactionId ? `/${transactionId}` : ''
  const url = `${primaryPath}${productPath}${transactionPath}${secondaryPath}`

  return new Promise((resolve, reject) => {
    axios({
      method,
      url,
      data,
      validateStatus: statusCode => resolveStatuses.includes(statusCode),
    })
      .then(
        response => {
          const result =
            response.status === 200 && response.data ? response.data : response
          resolve(result)
        },
        error => {
          if (!customHandleReject) {
            onError(error)
          }
          reject(error)
        }
      )
      .catch(error => {
        onError(error)
      })
  })
}

/*
// TODO: Needs to be removed here.
export const handleError = (e) => {
  // history.push('/error')
  console.warn(`no error handler defined, error: ${e}`)
}
*/

export default fesRequest
