/*
 * Initial commit NM sourced by si-it-audi-client d4edc34
 */
import _axios from 'axios'

export const axios = _axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

export const setAxiosToken = token => {
  if (token) {
    axios.defaults.headers.common['x-auth-token'] = token
  } else {
    delete axios.defaults.headers.common['x-auth-token']
  }
}

export const getAxiosTokenFromQueryParam = () => {
  const queryParams = new URLSearchParams(window.location.search)
  return queryParams.get('token')
}

export const setAxiosTokenFromQueryParam = () => {
  const token = getAxiosTokenFromQueryParam()
  setAxiosToken(token)
}
