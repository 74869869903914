export function getTextsDE(data) {

  return {
    "continueLater": {
      // 0
      "floatingButtonTooltip": "Später fortfahren",
      // ContinueLater texts when closeTabWarning prop is not set.
      "save": {
        // 1
        "title": "Möchten Sie mit Ihrem Antrag später fortfahren?",
        // 1a
        "validityText":"Bitte beachten Sie, dass der Link nur bis zum {{data.linkValidityText}}, gültig ist!",
        "textLine1": "Durch Klick auf \"Link senden\", erhalten Sie von uns einen Link per E-Mail, über den Sie Ihren Antrag später wieder aufrufen können.",
        "textLine2": "{{validityTextLine}}",
        "textLine3": "Klicken Sie auf \"Schließen\", um mit Ihrem Antrag fortzufahren.",
        "textLine4": "",
        "linkValidityDateFormat": "DD.MM.YYYY HH:mm",
        // 1h
        "btnBack": "Schließen"
      },
      // Alternative texts to "save" above when closeTabWarning prop is set.
      "closeTab": {
        // Not part of S&C 1.0
        // "title": "Desideri continuare in un altro momento?",
        // "textLine1": "Puoi concludere l'acquisto del piano di manutenzione per la tua {{productName}} più tardi. ",
        // "textLine2": "Cliccando su \"Procedi  in un altro momento\" riceverai un link al tuo indirizzo e-mail, tramite cui potrai riprendere il tuo acquisto esattamente dal punto in cui ll'avevi interrotto.",
        // "textLine3": "Tieni presente che il link sarà valido per sette giorni.",
        // "textLine4": "Clicca su \"Chiudi\"  per terminare la sessione.",
        // "btnBack": "Chiudi"
      },
      "form": {
        // 1b
        "customerEmail": "E-Mail",
        // 1c
        "customerPhoneCountryCode": "Ländervorwahl",
        // 1d
        "customerTelephoneNrTooltip": "Wir benötigen Ihre Mobilfunknummer, da wir Ihnen per SMS eine TAN zusenden, mit der Sie Ihren Antrag erneut aufrufen können.",
        // 1e
        "customerTelephoneNr": "Mobilfunknummer",
        // 1f (static part)
        // TODO: Missing as of 9.3.2021/marked red in content sheet
        "dataProtection": "dataProtection - missing in content sheet ",
        // 1f (link label part)
        // TODO: Missing as of 9.3.2021/marked red in content sheet
        "dataProtectionLinkText": "dataProtectionLinkText - missing in content sheet",
        // parameterized
        "dataProtectionLink": data.dataProtectionLink || "/page/privacy-cookie-policy",
        // optional, e.g. PT
        "termsAndConditionsIntro": "",
        "termsAndConditionsLinkText": "",
        "termsAndConditionsLink": "",

        "dataProtectionEnding": "",
        // ?
        "select": "",
        "requiredFieldsHint": "Alle mit * gekennzeichneten Felder sind Pflichtfelder.",
      },
      // 1i
      "btnContinue": "Link senden"
    },
    "mailDispatch": {
      // 2
      "heading": "Der Link wurde erfolgreich per E-Mail versendet",
      "icon": "semantic-checkmark",
      // 2b
      "paragraph": "Wir haben Ihnen den Link zum Fortfahren des Antrags an Ihre Adresse {{emailAddress}} gesendet. Bitte prüfen Sie Ihren Posteingang.",
      // 2c
      "sendLinkAgain": "E-Mail nochmals senden",
      // 2d
      "buttonText": "Schließen"
    },
    "tanAuthentication": {
      // 4
      "heading": "TAN bestätigen",
      // 4a
      // use \n for multiple paragrahs
      "content": "Geben Sie bitte die TAN ein, die wir Ihnen per SMS zugeschickt haben.",
      // 4b
      "inlineErrorMessage": "Sie haben nicht die richtige TAN eingegeben. Bitte versuchen Sie es erneut.",
      // 4c
      "formFieldHelperText": "TAN",
      // 4d
      "requestTanLink": "SMS-TAN nochmals anfordern",
      // 4e
      "buttonText": "TAN bestätigen",
      "cooldownText": "SMS-TAN erneut anfordern möglich in ",
      "cooldownUnit": " Sekunden."
    },
    "linkExpiry": {
      // 3
      "heading": "Link ist abgelaufen",
      // 3a
      // use \n for multiple paragraphs
      "paragraph": "Der Link, um mit Ihrem Antrag fortzufahren, ist abgelaufen.\n Bitte starten Sie den Antragsprozess erneut.",
      // 3b 
      // To be disabled as per content sheet 9.3.2021, 13.10.2021
      "primaryButton": "Zurück"
    },
    "validation": {
      // TODO
      "required": "Bitte füllen Sie dieses Pflichtfeld aus.",
      "minLength": "Bitte geben Sie die Mindestanzahl an Zeichen an.",
      "maxLength": "Sie haben die maximale Anzahl an Zeichen überschritten.",
      "invalidPhone": "Bitte geben Sie die Nummer ohne führende Nullen ein.",
      "invalidPhoneSpecialCheck": "Bitte geben Sie die Mobilfunknummer mit einer führenden 1 ein",
      "invalidEmail": "Bitte geben Sie eine gültige E-Mail-Adresse an.",
      "invalidField": "Bitte geben Sie nur gültige Zeichen an.",
    }
  }
}


