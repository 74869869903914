/*
 * Initial commit NM sourced by si-it-audi-client d4edc34
 */
export const SET_STEP_DATA = 'form//SET_STEP_DATA'
export const SET_FORM_DATA = 'form//SET_FORM_DATA'
export const RESET_FORM_DATA = 'form//RESET_FORM_DATA'

export const SET_DATA_MODELS = 'form//SET_DATA_MODELS'

export function setStepData(step, data) {
  return {
    type: SET_STEP_DATA,
    payload: {
      step,
      data,
    },
  }
}

export function setDataModels(data) {
  return {
    type: SET_DATA_MODELS,
    payload: data,
  }
}

export function getDataModels(store) {
  return store.form.dataModels
}

export function setFormData(data) {
  return {
    type: SET_FORM_DATA,
    payload: data,
  }
}

export function resetFormData(transactionId) {
  return {
    type: RESET_FORM_DATA,
    payload: transactionId,
  }
}

export function getStepData(store, step) {
  return store.form.stepData[step]
}

export function getFormData(store) {
  return store.form
}

const initialState = {
  transactionId: '',
  transactionStatus: '',
  stepData: {},
  dataModels: {},
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_FORM_DATA:
      return {
        ...state,
        ...action.payload,
      }

    case RESET_FORM_DATA:
      return {
        ...initialState,
        transactionId: action.payload.transactionId, // TODO ? operator
        token: action.payload.token, // TODO ? operator
        dataModels: state.dataModels,
      }

    case SET_STEP_DATA:
      return {
        ...state,
        stepData: {
          ...state.stepData,
          [action.payload.step]: action.payload.data,
        },
      }
    case SET_DATA_MODELS:
      return {
        ...state,
        dataModels: action.payload,
      }

    default:
      return state
  }
}
