import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Modal, Paragraph, Button } from '@vwfs-bronson/bronson-react'

export const MailDispatch = ({ 
  visible, 
  submitData, 
  displayData, 
  onClose,
  onConfirm, 
  onSendAgainSubmit,
  isCic = false
}) => {
  const { t } = useTranslation()
  const emailAddress = displayData.emailAddress
  const productName = displayData.productName
  const localizationSuffix = isCic ? '-cic' : ''
  let p = t(`sf-sac:mailDispatch${localizationSuffix}:paragraph`, { data: displayData, productName: productName, emailAddress: emailAddress })
  console.log(p)
  const pList = p.split('\n')
  console.log(pList)
  p = pList.map( e => <Paragraph>{e}</Paragraph>)
  return (
    <Modal
      testId="mailDispatchModal"
      id="mailDispatchModal"
      shown={visible}
      center
      title={t(`sf-sac:mailDispatch${localizationSuffix}:heading`)}
      buttonCancelText = {t(`sf-sac:mailDispatch${localizationSuffix}:buttonText`)}
      buttonConfirmText= {isCic ? t(`sf-sac:mailDispatch${localizationSuffix}:buttonConfirmText`) : null}
      className="c-modal--stretch"
      onClickOutside={onClose}
      onCancel={onClose}
      onConfirm={onConfirm}
      hideCloseButton
    >
      {isCic || <i
        className={`c-icon c-icon--xxlarge c-icon--[${t(
          'sf-sac:mailDispatch:icon'
        )}]`}
        role="img"
        aria-hidden="true"
      />}
      <br />
      <br />
      {p}
      <Button
        link
        onClick={() => {
          onSendAgainSubmit(submitData)
        }}
      >
        {t(`sf-sac:mailDispatch${localizationSuffix}:sendLinkAgain`)}
      </Button>
    </Modal>
  )
}
