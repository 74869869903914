export function getTextsGR(data) {

  return {
    "continueLater": {
      // 0
      "floatingButtonTooltip": "Αποθήκευση και Επεξεργασία αργότερα",
      // ContinueLater texts when closeTabWarning prop is not set.
      "save": {
        // 1
        "title": "Θα επιθυμούσατε να συνεχίσετε αργότερα;",
        // 1a
        "textLine1": "Τα στοιχεία που καταχωρήσατε θα αποθηκευτούν και μπορείτε να επιστρέψετε αργότερα προκειμένου να ολοκληρώσετε την διαδικασία. ",
        "textLine2": "Θα λάβετε σχετικό email στη διεύθυνση που έχετε δηλώσει.",
        "textLine3": "",
        "textLine4": "",
        "linkValidityDateFormat": "DD/MM/YYYY hh:mm a",
        // 1h
        "btnBack": "Επιστροφή στη φόρμα"
      },
      // Alternative texts to "save" above when closeTabWarning prop is set.
      "closeTab": {
        // Not part of S&C 1.0
        // "title": "Desideri continuare in un altro momento?",
        // "textLine1": "Puoi concludere l'acquisto del piano di manutenzione per la tua {{productName}} più tardi. ",
        // "textLine2": "Cliccando su \"Procedi  in un altro momento\" riceverai un link al tuo indirizzo e-mail, tramite cui potrai riprendere il tuo acquisto esattamente dal punto in cui ll'avevi interrotto.",
        // "textLine3": "Tieni presente che il link sarà valido per sette giorni.",
        // "textLine4": "Clicca su \"Chiudi\"  per terminare la sessione.",
        // "btnBack": "Chiudi"
      },
      "form": {
        // 1b
        "customerEmail": "Email",
        // 1c
        "customerPhoneCountryCode": "Κωδικός Χώρας",
        // 1d
        "customerTelephoneNrTooltip": "Χρειαζόμαστε τον αριθμό κινητού τηλεφώνου σας προκειμένου να σας αποστείλουμε κωδικό ταυτοποίησης για την επιστροφή σας.",
        // 1e
        "customerTelephoneNr": "Αριθμός κινητού τηλεφώνου",
        // 1f (static part)
        "dataProtection": "",
        // 1f (link label part)
        "dataProtectionLinkText": "",
        // parameterized
        "dataProtectionLink": data.dataProtectionLink,
        // optional, e.g. PT
        "termsAndConditionsIntro": "",
        "termsAndConditionsLinkText": "",
        "termsAndConditionsLink": "",

        "dataProtectionEnding": "",
        // ?
        "select": "",
      },
      // 1i
      "btnContinue": "Αποθήκευση και Επεξεργασία αργότερα"
    },
    "mailDispatch": {
      // 2
      "heading": "Το link έχει σταλεί! ",
      "icon": "semantic-checkmark",
      // 2b
      "paragraph": "Έχει σταλεί στο email σας το σχετικό link για να επιστρέψετε στην αίτησή σας, προκειμένου να επισυνάψετε τα σχετικά έγγραφα και να υποβάλετε την αίτησή σας. Το link θα είναι σε ισχύ για {{data.linkValidityText}} ημέρες ακόμα. Παακαλούμε ελέγξτε την αλληλογραφία σας.",
      // 2c
      "sendLinkAgain": "Αποστολή link",
      // 2d
      "buttonText": "Επιστροφή στη φόρμα/Έξοδος"
    },
    "tanAuthentication": {
      // 4
      "heading": "Καλώς ήλθατε!",
      // 4a
      // use \n for multiple paragrahs
      "content": "Πριν προχωρήσετε στην προβολή της αίτησής σας και την επισύναψη εγγράφων, θα θέλαμε να επιβεβαιώσουμε ότι είστε ο ίδιος.\nΠαρακαλούμε επιλέξτε την αποστολή PIN και  στη συνέχεια εισάγετε τον κωδικό που θα λάβετε στο κινητό που είχατε δηλώσει κατά την υποβολή της αίτησης.",
      // 4b
      "inlineErrorMessage": "Ο αριθμός Pin που καταχωρήσατε δεν είναι έγκυρος. Παρακαλούμε προσπαθήστε εκ νέου.",
      // 4c
      "formFieldHelperText": "Εισαγωγή PIN",
      // 4d
      "requestTanLink": "Αποστολή PIN/Επαναποστολή PIN",
      // 4e
      "buttonText": "Επιβεβαίωση PIN"
    },
    "linkExpiry": {
      // 3
      "heading": "Ο σύνδεσμος έχει λήξει!",
      // 3a
      // use \n for multiple paragraphs, \r for </br>
      "paragraph": "Εάν έχετε υποβάλει την αίτησή σας ήδη, θα την επεξεργαστούμε και θα ενημερωθείτε σχετικά.\nΕάν δεν είχατε ολοκληρώσει την υποβολή της αίτησης, μπορείτε να προχωρήσετε σε νέα αίτηση!\nΕάν έχετε οποιαδήποτε απορία, η Εξυπηρέτηση πελατών μας παραμένει στη διάθεσή σας.\nFinanceSupport.GR@vwfs.com\r+302108925900\rΔευτέρα-Παρασκευή\r9:00-17:00",
      // 3b 
      "primaryButton": "Επιστροφή στην αρχική σελίδα"
    },
    "validation": {
      "required": "This field is mandatory.",
      "minLength": "Please provide the minimum number of {{value}} characters.",
      "maxLength": "Maximum number of {{value}} characters exceeded.",
      "invalidPhone": "Please provide a valid phone number.",
      "invalidEmail": "Please provide a valid email address.",
      "invalidField": "Invalid character entered.",
    }
  }
}


